<template>
  <v-container>

    <ConfirmDialog
        :is-open.sync="dialogs.bindCustomerDialog.isOpened"
        :is-disable-confirm-button="!getDialogData(
            'bindCustomerDialog', 'customerIds'
        )"
        width="400"
        confirm-button-text="Bind"
        @confirm="bindCustomer"
    >
      <template v-slot:title>
        Select customer:
      </template>
      <template v-slot:body>
        <v-row>
          for composite customer:
        </v-row>
        <v-row class="font-weight-bold">
          {{ getDialogData('bindCustomerDialog', 'compositeCustomerName') }}
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-autocomplete
                item-text="label"
                item-value="id"
                label="customers"
                clearable
                dense
                chips
                small-chips
                multiple
                v-model="dialogs.bindCustomerDialog.data.customerIds"
                :items="customersList"
                :menu-props="{ closeOnContentClick: true }"
            />
          </v-col>
        </v-row>
      </template>
    </ConfirmDialog>

    <ConfirmDialog
        :is-open.sync="dialogs.unbindCustomerDialog.isOpened"
        width="400"
        confirm-button-text="Unbind"
        @confirm="unbindCustomer"
    >
      <template v-slot:title>
        Unbind customer:
        <span class="font-weight-bold ml-4">
          {{ currentCustomer(getDialogData('unbindCustomerDialog', 'customerId')).name }}
        </span>
      </template>
      <template v-slot:body>
        <v-row>
          for composite customer:
        </v-row>
        <v-row class="font-weight-bold">
          {{ getDialogData('unbindCustomerDialog', 'compositeCustomerName') }}
        </v-row>
      </template>
    </ConfirmDialog>

    <ConfirmDialog
        :is-open.sync="dialogs.setDashboard.isOpened"

        width="400"
        confirm-button-text="Set"
        @confirm="setDashboard"
    >
      <template v-slot:title>
        Select customer:
      </template>
      <template v-slot:body>
        <v-row>
          for composite customer:
        </v-row>
        <v-row class="font-weight-bold">
          {{ getDialogData('setDashboard', 'compositeCustomerName') }}
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-autocomplete
                item-text="name"
                item-value="id"
                label="dashboard"
                dense
                chips
                small-chips
                v-model="dialogs.setDashboard.data.dashboardId"
                :items="dashboards"
                :menu-props="{ closeOnContentClick: true }"
            />
          </v-col>
        </v-row>
      </template>
    </ConfirmDialog>

    <ConfirmDialog
        :is-open.sync="dialogs.removeDashboard.isOpened"
        width="400"
        confirm-button-text="Remove"
        @confirm="removeDashboard"
    >
      <template v-slot:title>
        Remove dashboard:
      </template>
      <template v-slot:body>
        <v-row>
          for composite customer:
        </v-row>
        <v-row class="font-weight-bold">
          {{ getDialogData('removeDashboard', 'compositeCustomerName') }}
        </v-row>
      </template>
    </ConfirmDialog>

    <v-data-table
        :options.sync="options"
        :footer-props="{ itemsPerPageOptions: [10, 25, 50, -1] }"
        :headers="headers"
        :items="compositeCustomers"
        :single-expand="true"
    >
      <template v-slot:top>
        <v-toolbar
            class="mt-4"
            flat
        >
          <CompositeCustomerForm v-on:create="addCompositeCustomer" />

          <v-btn
              icon
              outlined
              @click="fetchCompositeCustomers"
          >
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                >
                  mdi-refresh
                </v-icon>
              </template>
              refresh composite customer list
            </v-tooltip>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="remove(item.id)">
          <v-icon
              color="red"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

      <template v-slot:item.name="{ item }">
        <v-row>
          <v-edit-dialog
              @save="save(itemClone, item)"
              @open="itemClone = {...item}"
          >
            {{ item.name }}
            <template v-slot:input>
              <v-text-field
                  v-model="itemClone.name"
                  label="Edit"
                  single-line
                  counter
              />
            </template>
          </v-edit-dialog>
        </v-row>
      </template>

      <template v-slot:item.order="{ item }">
        <v-row>
          <v-edit-dialog
              @save="updateItemOrder(itemClone)"
              @open="itemClone = {...item}"
          >
            {{ item.order }}
            <template v-slot:input>
              <v-text-field
                  v-model="itemClone.order"
                  type="number"
                  label="Order"
                  single-line
                  counter
              />
            </template>
          </v-edit-dialog>
        </v-row>
      </template>

      <template v-slot:item.description="{ item }">
        <v-edit-dialog
            @save="save(itemClone, item)"
            @open="itemClone = {...item}"
        >
          <div v-if="item.description" class="font-weight-black">{{ item.description }}</div>
          <div v-else class="font-italic font-weight-thin red--text" v-text="'empty'"/>
          <template v-slot:input>
            <v-text-field
                v-model="itemClone.description"
                label="Edit"
                single-line
                counter
            />
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.customers="{ item }">
        <v-btn
            text
            color="teal accent-4"
            fab
            small
            @click="openDialog(
                'bindCustomerDialog',
                {
                  compositeCustomerName: item.name,
                  compositeCustomerId: item.id,
                },
            )"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    v-bind="attrs"
                    v-on="on"
                >
                  mdi-label
                </v-icon>
            </template>
            add customer
          </v-tooltip>
        </v-btn>
        <v-chip
            v-for="customer in item.customers"
            :key="customer.id"
            close
            @click:close="openDialog(
                'unbindCustomerDialog',
                {
                  compositeCustomerName: item.name,
                  compositeCustomerId: item.id,
                  customerId: customer.id,
                },
              )"
            class="ma-0"
            :color="customer.is_airport ? '#FF6E40' : '#c49c18'"
            small
        >
          {{ customer.name }}
          {{ customer.is_airport ? '(is apt)' : '' }}
        </v-chip>
      </template>

      <template v-slot:item.dashboard="{ item }">
        <v-btn
            v-if="item.dashboard_id === null"
            text
            color="teal accent-4"
            fab
            small
            @click="openDialog(
                'setDashboard',
                {
                  compositeCustomerName: item.name,
                  compositeCustomerId: item.id,
                },
            )"
        >
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <slot>
                <v-icon
                    v-bind="attrs"
                    v-on="on"
                >
                  mdi-label
                </v-icon>
              </slot>
            </template>
            add dashboard
          </v-tooltip>
        </v-btn>
        <v-chip
            v-if="item.dashboard_id !== null"
            close
            @click:close="openDialog(
                'removeDashboard',
                {
                  compositeCustomerName: item.name,
                  compositeCustomerId: item.id,
                  dashboardId: item.dashboard.id,
                },
              )"
            class="ma-0"
            small
        >
          {{ item.dashboard.name }}
        </v-chip>
      </template>

      <template v-slot:item.is_active="{ item }">
        <v-switch
            v-model="item.is_active"
            @change="save(item, item)"
        />
      </template>

      <template v-slot:item.is_native="{ item }">
        <v-switch
            v-model="item.is_native"
            @change="save(item, item)"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import CompositeCustomerForm from '../components/CompositeCustomerForm.vue';
import ConfirmDialog from '../components/dialogs/ConfirmDialog.vue';
import manageDialogsMixin from '../mixins/manageDialogsMixin';

export default {
  name: 'LogVersion',
  components: {
    CompositeCustomerForm,
    ConfirmDialog,
  },

  mixins: [
    manageDialogsMixin,
  ],

  data: () => ({
    itemClone: {},
    compositeCustomers: [],
    customersList: [],
    dashboards: [],

    headers: [
      { text: 'id', value: 'id' },
      { text: 'order', value: 'order' },
      { text: 'name', value: 'name' },
      { text: 'description', value: 'description' },
      { text: 'customers', value: 'customers' },
      { text: 'dashboard', value: 'dashboard', width: '150px' },
      { text: 'is native', value: 'is_native' },
      { text: 'is active', value: 'is_active' },
      { text: '', value: 'actions', sortable: false },
    ],
    options: {
      itemsPerPage: 10,
      sortBy: ['order'],
      sortDesc: [false],
      mustSort: true,
      multiSort: false,
    },

    dialogs: {
      bindCustomerDialog: {
        isOpened: false,
        data: {
          compositeCustomerName: '',
          customerIds: [],
          compositeCustomerId: null,
        },
        defaults: {
          compositeCustomerName: '',
          customerIds: [],
          compositeCustomerId: null,
        },
      },

      unbindCustomerDialog: {
        isOpened: false,
        data: {
          compositeCustomerName: '',
          customerId: null,
          compositeCustomerId: null,
        },
        defaults: {
          compositeCustomerName: '',
          customerId: null,
          compositeCustomerId: null,
        },
      },

      setDashboard: {
        isOpened: false,
        data: {
          compositeCustomerName: '',
          compositeCustomerId: null,
          dashboardId: null,
        },
        defaults: {
          compositeCustomerName: '',
          compositeCustomerId: null,
          dashboardId: null,
        },
      },

      removeDashboard: {
        isOpened: false,
        data: {
          compositeCustomerName: '',
          compositeCustomerId: null,
          dashboardId: null,
        },
        defaults: {
          compositeCustomerName: '',
          compositeCustomerId: null,
          dashboardId: null,
        },
      },
    },
  }),

  created() {
    this.fetchCustomersList();

    this.fetchDashboards();

    this.fetchCompositeCustomers();
  },

  methods: {
    ...mapActions({
      setSnack: 'snackbar/setState',
    }),

    async fetchCompositeCustomers() {
      await this.$requestHelpers
        .get('api/admin/compositeCustomer')
        .then((response) => {
          this.compositeCustomers = response.data;
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 403) {
              this.$router.push({ name: 'root' });
            }
          }
        });
    },

    addCompositeCustomer(compositeCustomer) {
      this.compositeCustomers.push(compositeCustomer);
    },

    save(itemClone, item) {
      this.$requestHelpers
        .patch(`api/admin/compositeCustomer/${item.id}`, itemClone)
        .then((response) => {
          if (response.data.success) {
            Object.assign(item, response.data.result);

            this.setSnack({
              color: 'green',
              text: 'saved',
            });
          } else {
            this.setSnack({
              color: 'red',
              text: response.data.message,
            });
          }
        });
    },

    updateItemOrder(itemClone) {
      this.$requestHelpers
        .post('api/admin/compositeCustomer/updateOrder',
          {
            id: itemClone.id,
            order: itemClone.order,
          })
        .then((response) => {
          if (response.data.success) {
            response.data.result.forEach((el) => {
              const compositeCustomer = this.compositeCustomers.find((item) => item.id === el.id);

              compositeCustomer.order = el.order;
            });

            this.setSnack({
              color: 'green',
              text: 'saved',
            });
          }
        });
    },

    remove(id) {
      const compositeCustomer = this.compositeCustomers.find((el) => el.id === id);
      this.$confirm(`Are you sure you want to delete composite customer ${compositeCustomer.name}?`).then((res) => {
        if (!res) return;

        this.$requestHelpers
          .delete(`api/admin/compositeCustomer/${id}`)
          .then((response) => {
            if (response.data.success) {
              this.setSnack({
                color: 'green',
                text: 'deleted',
              });
              this.compositeCustomers = this.compositeCustomers.filter((e) => e.id !== id);
            } else {
              this.setSnack({
                color: 'red',
                text: 'error',
              });
            }
          });
      });
    },

    bindCustomer() {
      this.$requestHelpers
        .post('api/admin/compositeCustomer/bindCustomers',
          {
            composite_customer_id: this.getDialogData('bindCustomerDialog', 'compositeCustomerId'),
            customer_ids: this.getDialogData('bindCustomerDialog', 'customerIds'),
          }).then((response) => {
          const item = this.compositeCustomers.find((el) => el.id === response.data.result.id);

          Object.assign(item, response.data.result);
        }).finally(() => {
          this.closeDialog('bindCustomerDialog');
        });
    },

    unbindCustomer() {
      this.$requestHelpers
        .post('api/admin/compositeCustomer/unbindCustomer',
          {
            composite_customer_id: this.getDialogData('unbindCustomerDialog', 'compositeCustomerId'),
            customer_id: this.getDialogData('unbindCustomerDialog', 'customerId'),
          }).then((response) => {
          const item = this.compositeCustomers.find((el) => el.id === response.data.result.id);

          Object.assign(item, response.data.result);
        }).finally(() => {
          this.closeDialog('unbindCustomerDialog');
        });
    },

    setDashboard() {
      this.$requestHelpers
        .post('api/admin/compositeCustomer/setDashboard',
          {
            composite_customer_id: this.getDialogData('setDashboard', 'compositeCustomerId'),
            dashboard_id: this.getDialogData('setDashboard', 'dashboardId'),
          }).then((response) => {
          const item = this.compositeCustomers.find((el) => el.id === response.data.result.id);

          Object.assign(item, response.data.result);
        }).finally(() => {
          this.closeDialog('setDashboard');
        });
    },

    removeDashboard() {
      this.$requestHelpers
        .post('api/admin/compositeCustomer/removeDashboard',
          {
            composite_customer_id: this.getDialogData('removeDashboard', 'compositeCustomerId'),
          }).then((response) => {
          const item = this.compositeCustomers.find((el) => el.id === response.data.result.id);

          Object.assign(item, response.data.result);
        }).finally(() => {
          this.closeDialog('removeDashboard');
        });
    },

    fetchCustomersList() {
      this.$requestHelpers
        .get('api/admin/customer')
        .then((response) => {
          this.customersList = response.data.map(
            (el) => ({
              id: el.id,
              name: el.name,
              label: (el.is_airport
                ? `${el.name} - is airport`
                : el.name),
            }),
          );
        });
    },

    fetchDashboards() {
      this.$requestHelpers.get('api/admin/dashboard/list')
        .then((response) => {
          this.dashboards = response.data;
        });
    },
  },

  computed: {
    currentCustomer() {
      return (id) => {
        const item = this.customersList.find((el) => el.id === id);
        return item || { id: null, name: 'n/a' };
      };
    },
  },
};
</script>
