<template>
  <v-container>
    <v-row>
      <v-col>
        <v-tabs
            v-model="currentCustomerTab"
        >
          <v-tab
              v-for="(tab, key) in customersTabs"
              :key="key"
          >
            {{ tab }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-tabs-items
        v-model="currentCustomerTab"
    >
      <v-tab-item
          :key="0"
      >
        <Customers/>
      </v-tab-item>
      <v-tab-item
          :key="1"
      >
        <CompositeCustomers />
      </v-tab-item>
    </v-tabs-items>

  </v-container>
</template>

<script>

import Customers from './Customer.vue';
import CompositeCustomers from './CompositeCustomer.vue';

export default {
  name: 'CustomerPage',
  components: {
    Customers,
    CompositeCustomers,
  },
  data: () => ({
    currentCustomerTab: 0,
    customersTabs: ['Customers', 'Composite customers'],
  }),

  created() {
  },
};
</script>
